import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import CommonMixin from "@/core/plugins/common-mixin";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { POST } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "bug-submit",
  data: function data() {
    return {
      bugSubmit: {
        title: null,
        priority: 3,
        description: null,
        attachment: []
      },
      priorityList: [{
        text: "High",
        value: 1
      }, {
        text: "Medium",
        value: 2
      }, {
        text: "Low",
        value: 3
      }],
      fieldDescriptions: [{
        field: "product_type",
        title: "Product Type",
        description: "Select if this item is a physical good or a service you're offering.",
        footer: "Note",
        footer_content: "Once you include this items in a transaction, you cannot change its type."
      }]
    };
  },
  components: {
    editor: TinyMCE,
    CreateUpdateTemplate: CreateUpdateTemplate,
    FileTemplate: FileTemplate
  },
  methods: {
    updateFiles: function updateFiles(param) {
      this.bugSubmit.attachment = param;
    },
    onSubmit: function onSubmit() {
      var _this = this;

      try {
        if (!_this.$refs.bugForm.validate()) {
          return false;
        }

        _this.formLoading = true;

        _this.$store.dispatch(POST, {
          url: "bug",
          data: _this.bugSubmit
        }).then(function () {
          _this.$router.push(_this.getDefaultRoute("bug.report", {
            query: {
              status: "all"
            }
          }));
        }).catch(function (error) {
          _this.logError(error);
        }).finally(function () {
          _this.formLoading = false;
        });
      } catch (error) {
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    }
  },
  mounted: function mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Bug",
      route: "bug"
    }]);
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    var _this = this;

    _this.routePreventDialog = true;
    PreventDialogEventBus.$on("stay-on-page", function () {
      _this.routePreventDialog = false;
      next(false);
    });
    PreventDialogEventBus.$on("leave-page", function () {
      _this.routePreventDialog = false;
      next();
    });
  }
};